import { Quiz } from "@/core/redux/store/quizzes/types";
import Image from "next/image";
import React from "react";

const TagToImage: Record<string, string> = {
  js: "/images/quiz/tags/js.png",
  redux: "/images/quiz/tags/redux.png",
  reactjs: "/images/quiz/tags/reactjs.png",
  typescript: "/images/quiz/tags/typescript.png",
  jsx: "/images/quiz/tags/jsx.png",
  nextjs: "/images/quiz/tags/nextjs.png",
  nodejs: "/images/quiz/tags/nodejs.png",
  vuejs: "/images/quiz/tags/vuejs.png",
  es6: "/images/quiz/tags/es6.png",
  libraries: "/images/quiz/tags/libraries.png",
  dom: "/images/quiz/tags/dom.png",
  other: "/images/quiz/tags/other.png",
};

type Props = {
  quiz: Quiz;
};

export const QuizTag: React.FC<Props> = ({ quiz }) => {
  const url = TagToImage[quiz.tag.slug] ?? TagToImage.other;
  return (
    <Image
      src={url}
      alt={quiz.tag.title}
      title={quiz.tag.title}
      className="inline-block mr-2 rounded md:inline h-5 w-5"
      width={24}
      height={24}
    />
  );
};
