import { FooterContent } from "../Common/Footer/Content";

export function Footer() {
  return (
    <footer className="footer">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-10 sm:py-14 lg:px-8">
        <FooterContent />
      </div>
    </footer>
  );
}
