import { featuredActions } from "@/core/redux/store/featured/actions";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { featuredSelectors } from "@/core/redux/store/featured/selectors";
import { QuestionPreview } from "@/components/generic/Question/Preview";
import Link from "next/link";
import Image from "next/image";
import { siteSelectors } from "@/core/redux/store/site/selectors";
import { useRouter } from "next/router";

export function Header() {
  const question = useSelector(featuredSelectors.question);
  const siteConfig = useSelector(siteSelectors.siteConfig);
  const dispatch = useDispatch();
  const router = useRouter();
  const section = useMemo(() => {
    if (router.asPath.includes("flashcards")) return "flashcards";
    return "quizzes";
  }, [router.asPath]);

  useEffect(() => {
    dispatch(featuredActions.loadQuestion());
  }, [dispatch]);

  return (
    <header className="header sticky top-0 z-10 mx-auto w-full border-b backdrop-blur ">
      <div className="w-full px-4 py-4 mx-auto flex justify-between max-w-8xl lg:px-4 flex-wrap md:flex-nowrap">
        <div className="flex md:flex-0 md:order-1 items-center h-9 md:w-36 text-center">
          <Link href="/" className="flex items-center mx-auto" title={siteConfig.siteDescription}>
            <Image src={`${siteConfig.siteAssetsBase}/logo.png`} className="h-8 w-8" width={64} height={64} alt={siteConfig.siteName} title={siteConfig.siteName} />
            <span className="self-center ml-3 text-2xl font-semibold font-sans whitespace-nowrap">{siteConfig.siteName}</span>
          </Link>
        </div>
        {question && (
          <div className="items-center text-center grow order-3 md:order-2 w-full md:w-auto mt-2">
            <div className="text-sm font-medium text-gray-900 md:my-0 ">
              <QuestionPreview question={question} />
            </div>
          </div>
        )}
        <div className="flex flex-row-reverse items-center w-auto order-2 md:order-3 md:w-36">
          {section == 'flashcards' ? (
            <Link
              className="py-2 px-5 btn-secondary inline-flex gap-x-1.5 items-right"
              href="/"
              title="Quizzes"
            >
              <span className="pr-1 text-sm">Quizzes</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
              </svg>
            </Link>
          ) : (
            <Link
              className="py-2 px-5 btn-secondary inline-flex gap-x-1.5 items-right"
              href="/flashcards"
              title="Flashcards"
            >
              <span className="pr-1 text-sm">Flashcards</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
              </svg>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}
