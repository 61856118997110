import React from "react";
import { Footer } from "./Layout/Footer";
import { Header } from "./Layout/Header";
import { PropsWithChildren } from "react";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="grow mb-6">
        <div className="text-center mx-auto max-w-8xl md:px-4">{children}</div>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
