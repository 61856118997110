import { QuizQuestion } from "@/core/redux/store/quiz/types";
import { QuizTag } from "../Quiz/QuizTag";
import { ArrowLongRightIcon } from "@heroicons/react/20/solid";
import Link from "next/link";

export const sliceToSpecialChar = (text: string, length: number): string => {
  const specialChar = ["`", "."];
  // stop/split at first special char
  let ret = text;
  for (let i = 0; i < Math.min(text.length, length); i++) {
    if (specialChar.includes(text[i])) {
      ret = ret.slice(0, i);
      break;
    }
  }
  if (ret.length > length) {
    return ret.slice(0, length) + "...";
  }
  return ret;
};

type Props = {
  question: QuizQuestion;
};
export const QuestionPreview: React.FC<Props> = ({ question }) => {
  return (
    <div className="text-sm font-medium md:my-0">
      <QuizTag quiz={question.quiz} />
      <span>{sliceToSpecialChar(question.preview || question.question, 65)}</span>
      <Link
        className="inline-flex items-center ml-2 link-primary"
        href={`/quiz/${question.quiz.slug}/question/${question.slug}`}
        title={`${question.quiz.title} - ${question.topic}`}
      >
        Answer
        <ArrowLongRightIcon className="w-3 h-4 ml-1 mt-1" />
      </Link>
    </div>
  );
};
