import { siteSelectors } from "@/core/redux/store/site/selectors";
import { NextSeo, NextSeoProps } from "next-seo";
import { useSelector } from "react-redux";

type SeoHeadProps = NextSeoProps;

export const SeoHead: React.FC<SeoHeadProps> = (props) => {
  const siteConfig = useSelector(siteSelectors.siteConfig);
  const siteSeoProps: NextSeoProps = {}

  if (siteConfig.social?.facebookPageId) {
    siteSeoProps["facebook"] = { appId: siteConfig.social.facebookPageId }
  }

  if (props.openGraph && siteConfig.social?.twitterUsername) {
    siteSeoProps["twitter"] = {
      handle: `@${siteConfig.social.twitterUsername}`,
      cardType: 'summary_large_image',
    };
  }

  return (
    <NextSeo
      {...siteSeoProps}
      {...props}
      additionalLinkTags={[
        {
          rel: "icon",
          href: `${siteConfig.siteAssetsBase}/favicon.ico`,
        },
      ]}
    />
  );
};
